<template>
  <swiper-container 
    ref="labelSwiper" 
    class="label-with-swiper"
    init="false"
    direction="vertical"
    autoplay-disable-on-interaction="false"
    allow-touch-move="false"
    observer="true"
    observe-parents="true"
    :dir="GB_cssRight ? 'rtl' : 'ltr'"
  >
    <swiper-slide
      v-for="(item, index) in labels"
      :key="index"
      :index="index"
    >
      <div
        class="swiper-content"
      >
        <img
          :src="item.imgSrc"
          :width="styleConfig.imgSize"
          :height="styleConfig.imgSize"
        />
        <span 
          class="label-text"
          v-html="item.labelText"
        >
        </span>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script>
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
if (typeof window !== 'undefined') {
  register()
}
const { GB_cssRight } = gbCommonInfo

export default {
  name: 'LabelWithSwiper',
  props: {
    labels: {
      type: Array,
      default: () => ([])
    },
    styleConfig: {
      type: Object,
      default: () => ({
        imgSize: 12,
      })
    },
  },
  data() {
    return {
      GB_cssRight,
      exposedLabelIds: [],
    }
  },
  mounted() {
    if (!this.labels.length) return
    this.initSwiper()
  },
  methods: {
    initSwiper() {
      this.$nextTick(() => {
        const options = {
          modules: [Autoplay],
          autoplay: { delay: 1500 },
          loop: true,
          on: {
            init: (s) => {
              this.handleExpose(s)
            },
            realIndexChange: (s) => {
              this.handleExpose(s)
            },
          }
        }

        Object.assign(this.$refs.labelSwiper, options)
        this.$refs.labelSwiper.initialize()
        this.swiper = this.$refs.labelSwiper?.swiper
      })
    },
    handleExpose(s) {
      // 获取真实的曝光标签
      const { realIndex } = s
      this.$emit('expose', { 
        item: this.labels[realIndex], 
        index: realIndex,
      })
    }
  },
}
</script>

<style lang="less" scoped>
.label-with-swiper {
  display: inline-flex;
  height: 16/37.5rem;
  margin: 0;
  overflow: hidden;
  .swiper-content {
    overflow: hidden;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    .label-text {
      margin-left: 4/37.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* rtl:begin:ignore */
      direction: ltr;
    }
  }
}
</style>
